<template>
    <ServicePage />
</template>

<script>

import ServicePage from '../components/ServicePage.vue'

export default {
    data: () => ({
    }),
    components: {
        ServicePage
    },
    methods: {
    },
    mounted() {
    }
}
</script>

